import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
    return (
        <section id='services'>
            <h5>WE ARE SKILLED ON</h5>
            <h2>Software Development Services</h2>

            <div className="container services__container">

                {/* WEB APPLICATIONS */}
                <article className="service">
                    <div className="service__head">
                        <h3>Web Applications</h3>
                    </div>

                    <ul className='service__list'>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Web applications have been at the core of our services.</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>We use modern backend and front end technologies to deliver a top quality product.</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Our developers are battle tested in the enterprise world and are ready to hit the ground
                                running in no time.</p>
                        </li>
                    </ul>
                </article>

                {/* END OF WEB APPLICATIONS */}

                {/* MOBILE APPLICATIONS */}
                <article className="service">
                    <div className="service__head">
                        <h3>Mobile Applications</h3>
                    </div>

                    <ul className='service__list'>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>We offer various apps and scalable solutions for diverse industry.</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>We believe in providing result-oriented app solutions.</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>We develop mobile apps after careful planning and applying necessary methodology to
                                create the right app for you.</p>
                        </li>
                    </ul>
                </article>

                {/* END OF WEB APPLICATIONS */}

                <article className="service">
                    <div className="service__head">
                        <h3>UI/UX Design</h3>
                    </div>

                    <ul className='service__list'>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Our designers, make it possible to convert your excellent ideas into meaningful user
                                interactions.</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>We focus on creating designs that leave a long lasting impact on the customers.</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Win your target audience’s heart and occupy their mind. </p>
                        </li>
                    </ul>
                </article>

                {/* END OF UI/UX */}

                {/* PROJECT MANAGEMENT */}
                <article className="service">
                    <div className="service__head">
                        <h3>Project Management</h3>
                    </div>

                    <ul className='service__list'>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Plan, execute, and track projects of any size.</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Our project managers can be crucial to ensure that features are delivered in time. </p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>With us you save time and become more profitable.</p>
                        </li>
                    </ul>
                </article>

                {/* END OF PROJECT MANAGEMENT */}

                {/* CLOUD SOLUTIONS */}
                <article className="service">
                    <div className="service__head">
                        <h3>Cloud Solutions</h3>
                    </div>

                    <ul className='service__list'>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>We work with AWS and Azure to deliver cloud native applications. </p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p> Whether it is a PoC/MVP or a developed application, our engineers bring a lot of
                                experience to the table.. </p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>We’re uptime obsessed and committed to delivering the best colocation and cloud solution
                                for our customers.</p>
                        </li>
                    </ul>
                </article>

                {/* END OF CLOUD SOLUTIONS*/}

                {/* QUALITY ASSURANCE AND TESTING*/}
                <article className="service">
                    <div className="service__head">
                        <h3>QA & Testing</h3>
                    </div>

                    <ul className='service__list'>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>We offer complete testing and quality assurance services for web, desktop and mobile apps
                                as well.</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Our team is well versed with standard test types and beyond.</p>
                        </li>
                        <li>
                            <BiCheck className='service__list-icon'/>
                            <p>Expert QA work on every project to ensure that the final outcome meets the most stringent
                                quality standards.</p>
                        </li>

                    </ul>
                    {/* END OF QUALITY ASSURANCE AND TESTING */}
                </article>

            </div>
        </section>
    )
}

export default Services