import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoLinkedin} from 'react-icons/io'
import {BsWhatsapp} from 'react-icons/bs'
import FOOTER_LOGO from "../../assets/FOOTER_LOGO.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer>
      {/* <a href="#" className='footer__logo'>SKONITECH SOLUTIONS</a> */}
     
      <div className="footer__logo">
          <img src={FOOTER_LOGO} alt="me" />
      </div>


      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Our Expertise</a></li>
        <li><a href="#services">Our Services</a></li>
        {/* <li><a href="#portfolio">Portfolio</a></li> */}
        {/* <li><a href="#testimonials">Testimonials</a></li> */}
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/company/skonitech-solutions/" target="_blank"><IoLogoLinkedin/></a>
        <a href="https://www.instagram.com/skonitech/" target="_blank"><FiInstagram/></a>
        <a href="https://www.facebook.com/skonitech" target="_blank"><FaFacebookF/></a>
        <a href="https://wa.me/355696109833" className="whatsapp_float" target="_blank" rel="noopener noreferrer"><BsWhatsapp /></a>   
      </div>

      <div className="footer__copyright">
        <small>&copy;Copyright @ {currentYear} Skonitech Solutions. All Rights Reserved.</small>
      </div>

    </footer>
  )
}

export default Footer