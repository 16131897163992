import React from 'react'
// import CV from '../../assets/cv.pdf'

const CTA = () => {
  return (
    <div className='cta'>
        {/* <a href={CV} download className='btn'>Download CV</a> */}
        <a href="https://calendly.com/skonitech/chat-with-skonitech" target="_blank" className='btn'>Talk to an Expert</a>
        <a href="#contact" className='btn btn-primary'>Contact Us</a>
    </div>
  )
}

export default CTA