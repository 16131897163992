import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiPhoneLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import {BiMap} from 'react-icons/bi'
import { useRef } from 'react';
import emailjs from 'emailjs-com'
import ReCAPTCHA from "react-google-recaptcha";

import emailConfig from '../../utils/emailkey';
import { toast } from "react-toastify";

const recaptchaRef = React.createRef();




const Contact = () => {
  const form = useRef();
  // let captchaValue = false;

  function onChange(value) {
    // console.log("Captcha value:", value);
    // captchaValue = value;
  }

  const sendEmail = (e) => {

    e.preventDefault();

    // if(captchaValue){
      const name = e.target[0].value;
      const email = e.target[1].value;
      const message = e.target[2].value;

      let emailData = {
        from_name: name,
        to_name: "Skonitech",
        message: message,
        reply_to: email,
      };

      // emailjs
      //   .send(
      //     emailConfig.SERVICE_ID,
      //     emailConfig.TEMPLATE_ID,
      //     emailData,
      //     emailConfig.PUBLIC_KEY
      //   )
      //   .then(
      //     function (response) {
      //       console.log("SUCCESS!", response.status, response.text);
      //     },
      //     function (error) {
      //       console.log("FAILED...", error);
      //     }
      //   );



    emailjs.send(emailConfig.SERVICE_ID, emailConfig.TEMPLATE_ID, emailData, emailConfig.PUBLIC_KEY)
      .then((response) => {
        // console.log("SUCCESS!", response.status, response.text);
        // console.log("show alert email sent with success");

        toast.success('Thank you! Your message was successfully submitted.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        e.target.reset();
      })
      .catch((error) => {
        console.log("Error during send email", error);
        console.log("show alert error sending email");
      })

      


    // }else{
    //   console.log("invalid captcha")
    // }
  };


  // const sendEmail = (e) => { 
  //   e.preventDefault()
  //   console.log('Sending')
  // let data = {
  //     name,
  //     email,
  //     message
  //   }
  // fetch('', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json, text/plain, */*',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(data)
  //   })
  // };

  return (
    <section id='contact'>
      <h5>We’d love to hear from you.</h5>
      <h2>Let's Talk About Your Project. Let's discuss about your idea.</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email Us</h4>
            {/* <h5>info@skonitech.com</h5> */}
            <a href="mailto:info@skonitech.com" target="_blank">info@skonitech.com</a>
          </article>
          <article className="contact__option">
            <RiPhoneLine className='contact__option-icon'/>
            <h4>Call Us</h4>
            {/* <h5>(+355) 69 61 09 833</h5> */}
            {/* <a href="https://m.me/ernest.achiever" target="_blank">Send a message</a> */}
            <a href="tel:(+355)696109833"> (+355) 69 61 09 833</a>
          </article>
          <article className="contact__option">
            <BiMap className='contact__option-icon'/>
            <h4>Address</h4>
            <h5> Rruga e Kavajës 50 Ish Volksvagen, Tiranë 1001, Albania</h5>
            {/* <a href="https://api.whatsapp.com/send?phone=+355696109833" target="_blank">Send a message</a> */}
          </article>

          {/* <article className="contact__option">
            <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h5>(+355) 69 610 9833</h5>
            <a href="https://api.whatsapp.com/send?phone=+355696109833" target="_blank">Send a message</a>
          </article> */}
        </div>
        
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name *' required />
          <input type="email" name='email' placeholder='Your Email *' required />
          <textarea name="message" rows="7" placeholder='Short description of your project *' required ></textarea>
          {/* <ReCAPTCHA           
            ref={recaptchaRef}
            sitekey="6Lcq0fQbAAAAAPNj695xlLH3CqGPFd7ZooZRzx4z" 
            onChange={onChange} /> */}
          <ReCAPTCHA size="invisible" ref={recaptchaRef} size="invisible" sitekey="6Lcq0fQbAAAAAPNj695xlLH3CqGPFd7ZooZRzx4z" onChange={onChange} />
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact