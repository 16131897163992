import React from 'react'
import {BsInstagram, BsLinkedin, BsFacebook} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/company/skonitech-solutions/" target="_blank"><BsLinkedin/></a>
        <a href="https://www.instagram.com/skonitech/" target="_blank"><BsInstagram/></a>
        <a href="https://www.facebook.com/skonitech" target="_blank"><BsFacebook/></a>        
    </div>
  )
}

export default HeaderSocials