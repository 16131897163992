import React from 'react'
import './about.css'
import ME from '../../assets/me-about.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Let's Introduce to Skonitech Solutions</h5>
      <h2>Who we are</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>15+ <br/> Years Working</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>25+ <br/> Worldwide</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>150+ <br/>Completed</small>
            </article>
          </div>

          <p>
            {/*We are a comprehensive product design agency.*/}
            {/*We specialize in supporting clients in the development of physical and digital products based on the behavior of people.*/}
            {/*We help companies to transform their ideas into real products, covering with them all the phases that the creation of a product requires, such as design, prototyping or execution.*/}
            {/*We have the capacity and experience to develop all kinds of products for any industry and market.*/}
            {/*We are a Software Company, our main focus is helping companies speed-up their software product development and entry to the market.*/}

            Skonitech Solutions is a premier software agency with a decade of experience in delivering innovative and reliable tech solutions.
            We specialize in transforming ideas into cutting-edge software, ensuring our clients stay ahead in a dynamic digital landscape.
            At Skonitech, we blend expertise with creativity to craft exceptional results, tailored to meet your unique business needs.
          </p>
          <p>Have a project in mind?</p>

          <a href="#contact" className='btn btn-primary'>Let us collaborate!</a>
        </div>
      </div>
    </section>
  )
}

export default About