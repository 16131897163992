import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'
import {DiAndroid, DiPostgresql} from 'react-icons/di'
import {IoLogoIonic} from 'react-icons/io'
import {GrReactjs} from 'react-icons/gr'
import {FaAward, FaAws} from 'react-icons/fa'
import {SiAzuredevops, SiPostgresql} from 'react-icons/si'


import {AiOutlineHtml5} from 'react-icons/ai'
import {DiCss3Full} from 'react-icons/di'
import {IoLogoJavascript} from 'react-icons/io'
import { SiTypescript } from "react-icons/si";
import {DiBootstrap} from 'react-icons/di'
import {RiAngularjsLine} from 'react-icons/ri'
import {IoLogoNodejs} from 'react-icons/io'
import { SiNestjs } from "react-icons/si";
import {IoLogoPython} from 'react-icons/io'
import {SiPhp} from 'react-icons/si'
import {SiDotnet} from 'react-icons/si'
import {IoLogoApple} from 'react-icons/io'
import {SiMysql} from 'react-icons/si'
import {SiMongodb} from 'react-icons/si'
import {SiPowerbi} from 'react-icons/si'
import {DiMsqlServer} from 'react-icons/di'
import {GrOracle} from 'react-icons/gr'
import {FiUsers} from "react-icons/fi";
import {VscFolderLibrary} from "react-icons/vsc";


const Experience = () => {
  return (
      <section id='experience'>

        <div className="our_expertise_container">
          <p>Skonitech connects business with the power of technology.</p>
          <p>Based on client's requirement we choose the right technologies, programming languages and frameworks.</p>
          <p>This is just to guarantee high-performance and scalable solutions over time.</p>
        </div>

        {/*<h5>Skonitech connects business with the power of technology.</h5>*/}
        {/*<h5>Based on client's requirement we choose the right technologies, programming languages and frameworks.</h5>*/}
        {/*<h5>This is just to guarantee high-performance and scalable solutions over time.</h5>*/}
        <h2>Our Expertises</h2>

        <div className="container experience__container">
          <div className="experience__frontend">
            <h3>Frontend Development</h3>
            <div className="experience__content">
              <article className='experience__details'>
                <GrReactjs className='experience__details-icon'/>
                <div>
                  <h4>React JS</h4>
                </div>
              </article>
              <article className='experience__details'>
                <RiAngularjsLine className='experience__details-icon'/>
                <div>
                  <h4>Angular</h4>
                </div>
              </article>
              <article className='experience__details'>
                <AiOutlineHtml5 className='experience__details-icon'/>
                <div>
                  <h4>HTML</h4>
                </div>
              </article>
              <article className='experience__details'>
                <DiCss3Full className='experience__details-icon'/>
                <div>
                  <h4>CSS</h4>
                </div>
              </article>
              <article className='experience__details'>
                <IoLogoJavascript className='experience__details-icon'/>
                <div>
                  <h4>JavaScript</h4>
                </div>
              </article>
              <article className='experience__details'>
                <SiTypescript className='experience__details-icon'/>
                <div>
                  <h4>Typescript</h4>
                </div>
              </article>
              <article className='experience__details'>
                <DiBootstrap className='experience__details-icon'/>
                <div>
                  <h4>Bootstrap</h4>
                </div>
              </article>

            </div>
          </div>
          {/* END OF FRONTEND */}

          <div className="experience__backend">
            <h3>Backend Development</h3>
            <div className="experience__content">
              <article className='experience__details'>
                <IoLogoNodejs className='experience__details-icon'/>
                <div>
                  <h4>Node JS</h4>
                  {/* <small className='text-light'>Experienced</small> */}
                </div>
              </article>
              <article className='experience__details'>
                <SiNestjs className='experience__details-icon'/>
                <div>
                  <h4>Nest JS</h4>
                </div>
              </article>
              <article className='experience__details'>
                <SiDotnet className='experience__details-icon'/>
                <div>
                  <h4>.NET Core / C#</h4>
                  {/* <small className='text-light'>Experienced</small> */}
                </div>
              </article>
              <article className='experience__details'>
                <IoLogoPython className='experience__details-icon'/>
                <div>
                  <h4>Python</h4>
                  {/* <small className='text-light'>Intermediate</small> */}
                </div>
              </article>
              <article className='experience__details'>
                <SiPhp className='experience__details-icon'/>
                <div>
                  <h4>PHP</h4>
                </div>
              </article>
            </div>
          </div>

          <div className="experience__frontend">
            <h3>Mobile Development</h3>
            <div className="experience__content">
              <article className='experience__details'>
                < GrReactjs className='experience__details-icon'/>
                <div>
                  <h4>React Native</h4>
                </div>
              </article>
              <article className='experience__details'>
                <IoLogoIonic className='experience__details-icon'/>
                <div>
                  <h4>Ionic</h4>
                </div>
              </article>
              <article className='experience__details'>
                <DiAndroid className='experience__details-icon'/>
                <div>
                  <h4>Android</h4>
                </div>
              </article>
              <article className='experience__details'>
                <IoLogoApple className='experience__details-icon'/>
                <div>
                  <h4>iOS</h4>
                </div>
              </article>
            </div>
          </div>


          <div className="experience__frontend">
            <h3>Database Development</h3>
            <div className="experience__content">
              <article className='experience__details'>
              <SiMysql className='experience__details-icon'/>
                <div>
                  <h4>MySQL</h4>
                </div>
              </article>
              <article className='experience__details'>
                <DiPostgresql className='experience__details-icon'/>
                <div>
                  <h4>Postgre SQL</h4>
                </div>
              </article>
              <article className='experience__details'>
                <SiPostgresql className='experience__details-icon'/>
                <div>
                  <h4>Mongo DB</h4>
                </div>
              </article>
              <article className='experience__details'>
                <DiMsqlServer className='experience__details-icon'/>
                <div>
                  <h4>Microsoft SQL Server</h4>
                </div>
              </article>
              <article className='experience__details'>
                <GrOracle className='experience__details-icon'/>
                <div>
                  <h4>Oracle</h4>
                </div>
              </article>
            </div>
          </div>

          <div className="experience__frontend">
            <h3>BI and Reporting</h3>
            <div className="experience__content">

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icon'/>
                <div>
                  <h4>SQL Server Reporting Services</h4>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icon'/>
                <div>
                  <h4>SQL Server Integration Services</h4>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icon'/>
                <div>
                  <h4>SQL Server Analysis Services</h4>
                </div>
              </article>

              <article className='experience__details'>
                <SiPowerbi className='experience__details-icon'/>
                <div>
                  <h4>Power BI</h4>
                </div>
              </article>
            </div>
          </div>

          <div className="experience__frontend">
            <h3>Cloud</h3>
            <div className="experience__content">
              <article className='experience__details'>
                <SiAzuredevops className='experience__details-icon'/>
                <div>
                  <h4>Microsoft Azure</h4>
                </div>
              </article>
              <article className='experience__details'>
                <FaAws className='experience__details-icon'/>
                <div>
                  <h4>Amazon Web Services (AWS)</h4>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Experience