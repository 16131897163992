import React from "react";
import "./header.css";
import CTA from "./CTA";
import LOGO from "../../assets/me.svg";
import HeaderSocial from "./HeaderSocials";
import FOOTER_LOGO from "../../assets/FOOTER_LOGO.svg";

const Header = () => {
  return (
    <header>
      {/* <div className="container header__container"> */}
      <div className="container header__container">
        <div className="topheader">
          <img src={LOGO} alt="me" width="50%" height="100%"/>
        </div>

        <div className="bottomHeader">
           <h4>Welcome to Skonitech Solutions</h4>
          <h1>Software Development & Consulting Services</h1>
          {/*<h1>For Your Business</h1>*/}
          {/*<h1>Custom Software</h1>*/}
          {/*<h1>Development Company</h1>*/}

          <h5 className="text-light">
            A Software Company skilled on technology and innovative
            software solutions.
          </h5>
          <CTA />
          <HeaderSocial />
          <a href="#about" className="scroll__down"> Scroll Down </a>
        </div>
      </div>
    </header>
  );
};

export default Header;

// import React from 'react'
// import './header.css'
// import CTA from './CTA'
// import ME from '../../assets/me.png'
// import HeaderSocial from './HeaderSocials'

// const Header = () => {
//   return (
//     <header>
//         {/* <div className="me">
//           <img src={ME} alt="me" />
//         </div> */}

//       {/* <div className="me">
//           <img src={ME} alt="me" />
//       </div> */}

//       <div className="container header__container">
//         <h5>Welcome to Skonitech Solutions</h5>
//         <h1>Software Development Services</h1>
//         <h1>For Your Business</h1>
//         <h5 className="text-light">We are a Software Company skilled on technology and innovative software solutions.</h5>
//         <CTA />
//         <HeaderSocial />

//         <div className="me">
//           <img src={ME} alt="me" />
//         </div>

//         <a href="#about" className='scroll__down'>Scroll Down</a>
//       </div>
//     </header>
//   )
// }

// export default Header
