import React from 'react'
// import Header from './components/header/Header'
import CustomHeader from './components/header/CustomHeader'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
// import Portfolio from './components/portfolio/Portfolio'
// import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App = () => {
    return (
        <>
            {/*<Header/>*/}
            <CustomHeader/>
            <Nav/>
            <About/>
            <Experience/>
            <Services/>
            {/*<Portfolio/>*/}
            {/*<Testimonials/>*/}
            <Contact/>
            <Footer/>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export default App